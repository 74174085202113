import React, { useState, useEffect } from 'react';
import './HomePage.css';
import './ItemsStyle.css';
import { useNavigate, useLocation } from 'react-router-dom';

const Region = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const region = location.state?.region || 'NorthEast';
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => { 
    fetchPlaces();
  }, [region]);

  const fetchPlaces = async () => {
    try {
      const response = await fetch(`/api/places/${encodeURIComponent(region.toLowerCase())}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched data:', JSON.stringify(data, null, 2));

      if (!Array.isArray(data.data)) {
        throw new Error('Data is not an array');
      }

      setPlaces(data.data);
      setError(null);
    } catch (e) {
      console.error('Fetch error:', e);
      setError(`Failed to fetch places for ${region}. Error: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceSelect = (place) => {
    navigate('/ContentDisplayOne', { 
      state: { 
        region: region,
        title: place.title,
        id: place._id // Pass the ID for content retrieval
      } 
    });
  };

  const PlaceComponent = ({ place }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
      const loadImage = async () => {
        try {
          const imageModule = await import(`../assets/${place.image}`);
          setImageSrc(imageModule.default);
        } catch (err) {
          console.error(`Error loading image: ${place.image}`, err);
          setImageSrc(null);
        }
      };

      if (place.image) {
        loadImage();
      }
    }, [place.image]);

    return (
      <div className="item" onClick={() => handlePlaceSelect(place)}>
        <h2>{place.title || 'No Title'}</h2>
        <div className="item-content">
          {imageSrc ? (
            <img src={imageSrc} alt={place.title || 'No Title'} />
          ) : (
            <div>Image not available</div>
          )}
          <p>{place.description || 'No Description'}</p>
        </div>
      </div>
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="home-page">
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
      </header>
      <h1>{region} Region</h1>
      <div className="items-container">
        {places.length > 0 ? (
          places.map((place) => (
            <PlaceComponent
              key={place._id}
              place={place}
            />
          ))
        ) : (
          <div>No places found for {region}</div>
        )}
      </div>
      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );  
};

export default Region;
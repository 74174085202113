import React, { useEffect } from 'react';
import './HomePage.css';
import banner from '../assets/banner.jpg'
import northamerica from '../assets/northamerica.jpg'
import centralamerica from '../assets/centralamerica.jpg'
import europe from '../assets/europe.jpg'
import asia from '../assets/asia.jpg'
import oceania from '../assets/oceania.jpg'

import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "The Half Time Travel Website";
  }, []);

  const handleBlockClick = (destination) => {
    navigate(`/${destination.toLowerCase()}`);
  };

  return (
    <div className="home-page">
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
        <div className="banner">
          <img src={banner} alt="Banner" className="banner-image" />
          <h1 className="banner-text">Welcome to The Half Time Travel Website</h1>
        </div>
      </header>
      <div className="common-block">
        <p>We love to travel as a family—exploring new destinations, experiencing diverse cultures, and embracing the life of true wanderers. When we plan a trip, the process of creating the itinerary is often a daunting task. We prefer to explore like locals—renting cars, staying in Airbnbs, and stepping off the beaten path. However, traditional travel sites tend to focus mostly on popular attractions, which don’t always align with the kind of experiences we’re seeking. Since none of us have usually visited the destination before, it requires a lot of research to figure out where to stay, what to see, where to eat, and which activities to book. In recent years, my son has taken over the responsibility of planning, and he’s done an excellent job creating itineraries with the help of generative AI tools like ChatGPT. But while AI is helpful, it lacks the human touch and often falls short in some areas.</p>
        <p>For a long time, I’ve wanted to create a log of all our travel experiences in one place—a space that not only serves as our family travelogue, allowing us to relive our cherished memories, but also as a resource for others. We hope that people will read about our adventures and, perhaps, feel inspired to visit some of the places we’ve been. The goal is to approach each destination as a first-time traveler and to provide an alternative to the overwhelming flood of information that the internet and AI often throw at us.</p>
        <p>The website’s landing page is organized by continent. When you click on a continent, you can explore specific regions or countries. By selecting a region or country, you'll discover the places we’ve visited. For each destination, we’ve included detailed itineraries, along with insights about the attractions we experienced, and all the essential details to help you plan your own journey.</p>
        <p><strong>Why "Half Time Whistle”?</strong> In any game, a whistle signals a break—a time to refuel, rejuvenate, and rethink. Our Half Time Whistle website is inspired by this concept, encouraging you to take a pause from your busy life to wander, explore, and rediscover the world.</p>
        <p>Half Time Whistle is not just a travel website; it’s a piece of our heart and soul. It’s a collection of our memories, woven together with passion and love. We hope you enjoy this space as much as we enjoy creating and nurturing it. Thank you for visiting, and we look forward to your comments and feedback.</p>
        <p>Shantanu, Kapila, Shaurya, and Nitara</p>  
      </div>
      <main className="main-content">
        <div className="content-blocks">
          <div className="block featured-block">
            <div className="star-icon">★</div>
            <div className="block-content">
              <img src={banner} alt="Features" />
              <p>Coming Up - Smoky Mountains National Park - Nov'24</p>
            </div>
          </div>

          <div className="block" onClick={() => handleBlockClick('NorthAmerica')}>
            <div className="block-content">
              <img src={northamerica} alt="North America" />
              <p>North America</p>
            </div>
          </div>
          <div className="block" onClick={() => handleBlockClick('CentralAmerica')}>
            <div className="block-content">
              <img src={centralamerica} alt="Central America" />
              <p>Central America</p>
            </div>
          </div>
          <div className="block" onClick={() => handleBlockClick('Europe')}>
            <div className="block-content">
              <img src={europe} alt="Europe" />
              <p>Europe</p>
            </div>
          </div>
          <div className="block" onClick={() => handleBlockClick('Asia')}>
            <div className="block-content">
              <img src={asia} alt="Asia" />
              <p>Asia</p>
            </div>
          </div>
          <div className="block" onClick={() => handleBlockClick('Oceania')}>
            <div className="block-content">
              <img src={oceania} alt="Oceania" />
              <p>Oceania</p>
            </div>
          </div>
        </div>
      </main>

      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );
};

export default HomePage;
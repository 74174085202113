import React from 'react';
import './HomePage.css';
import banner from '../assets/europe.jpg'
import italy from '../assets/italy.jpg'
import france from '../assets/france.jpg'
import monaco from '../assets/monaco.jpg'
import england from '../assets/england.jpg'
import Iceland from '../assets/iceland.jpg'



import { useNavigate } from 'react-router-dom';

const Europe = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <div className="home-page">
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
        <div className="banner">
          <img src={banner} alt="Banner" className="banner-image" />
          <h1 className="banner-text">Welcome to The Half Time Travel Europe Page</h1>
        </div>
      </header>

      <main className="main-content">
        <div className="content-blocks">
          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'Italy')}
          >
            <div className="block-content">
              <img src={italy} alt="Italy" />
              <p>Italy</p>
            </div>
          </div>


          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'France')}
          >
            <div className="block-content">
              <img src={france} alt="France" />
              <p>France</p>
            </div>
          </div>

          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'Monaco')}
          >
            <div className="block-content">
              <img src={monaco} alt="Monaco" />
              <p>Monaco</p>
            </div>
          </div>
          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'England')}
          >
            <div className="block-content">
              <img src={england} alt="England" />
              <p>England</p>
            </div>
          </div>

          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'Iceland')}
          >
            <div className="block-content">
              <img src={Iceland} alt="Iceland" />
              <p>Iceland</p>
            </div>
          </div>

          

        </div>
      </main>

      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );
};

export default Europe;
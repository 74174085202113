import React from 'react';
import './HomePage.css';
import northeast from '../assets/northamerica.jpg'
import midwest from '../assets/midwest.jpg'
import west from '../assets/la.jpg'
import southcentral from '../assets/southcentral.JPG'
import jamaica from '../assets/jamaica.jpg'


import { useNavigate } from 'react-router-dom';

const NorthAmerica = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <div className="home-page">
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
        <div className="banner">
          <img src={northeast} alt="Banner" className="banner-image" />
          <h1 className="banner-text">Welcome to The Half Time Travel Americas Page</h1>
        </div>
      </header>

      <main className="main-content">
        <div className="content-blocks">
          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'NorthEast')}
          >
            <div className="block-content">
              <img src={northeast} alt="North East" />
              <p>North East</p>
            </div>
          </div>
          <div 
            className="block"
            onClick={() => handleBlockClick('Region', 'MidWest')}
          >
            <div className="block-content">
              <img src={midwest} alt="Mid West" />
              <p>Mid West</p>
            </div>
          </div>
          <div 
            className="block"
            onClick={() => handleBlockClick('Region', 'West')}
          >
            <div className="block-content">
              <img src={west} alt="West" />
              <p>West</p>
            </div>
          </div>
          <div 
            className="block"
            onClick={() => handleBlockClick('Region', 'SouthCentral')}
          >
            <div className="block-content">
              <img src={southcentral} alt="SouthCentral" />
              <p>South Central</p>
            </div>
          </div>
          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'Jamaica')}
          >
            <div className="block-content">
              <img src={jamaica} alt="Jamaica" />
              <p>Jamaica</p>
            </div>
          </div>


        </div>
      </main>

      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );
};

export default NorthAmerica;
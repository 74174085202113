import React from 'react';
import './HomePage.css';
import banner from '../assets/oceania.jpg'
import australia from '../assets/australia.jpg'



import { useNavigate } from 'react-router-dom';

const CentralAmerica = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <div className="home-page">
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
        <div className="banner">
          <img src={banner} alt="Banner" className="banner-image" />
          <h1 className="banner-text">Welcome to The Half Time Travel Oceania Page</h1>
        </div>
      </header>

      <main className="main-content">
        <div className="content-blocks">
          <div 
            className="block" 
            onClick={() => handleBlockClick('Region', 'Australia')}
          >
            <div className="block-content">
              <img src={australia} alt="Australia" />
              <p>Australia</p>
            </div>
          </div>
        </div>
      </main>

      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );
};

export default CentralAmerica;